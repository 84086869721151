export const getTestType = () => {
  return [
    { key: 0, header: "Test_Type", id: "Test_Type" },
    { key: 1, label: "Pregnancy_Test", viewing: false },
    { key: 2, label: "Ovulation_Test", viewing: false },
  ];
};

export const getCycleDays = () => {
  return [
    { key: 3, header: "Cycle_Day", id: "Cycle_Day" },
    { key: 4, label: "CD 1", viewing: false },
    { key: 5, label: "CD 2", viewing: false },
    { key: 6, label: "CD 3", viewing: false },
    { key: 7, label: "CD 4", viewing: false },
    { key: 8, label: "CD 5", viewing: false },
    { key: 9, label: "CD 6", viewing: false },
    { key: 10, label: "CD 7", viewing: false },
    { key: 11, label: "CD 8", viewing: false },
    { key: 12, label: "CD 9", viewing: false },
    { key: 13, label: "CD 10", viewing: false },
    { key: 14, label: "CD 11", viewing: false },
    { key: 15, label: "CD 12", viewing: false },
    { key: 16, label: "CD 13", viewing: false },
    { key: 17, label: "CD 14", viewing: false },
    { key: 18, label: "CD 15", viewing: false },
    { key: 19, label: "CD 16", viewing: false },
    { key: 20, label: "CD 17", viewing: false },
    { key: 21, label: "CD 18", viewing: false },
    { key: 22, label: "CD 19", viewing: false },
    { key: 23, label: "CD 20", viewing: false },
    { key: 24, label: "CD 21", viewing: false },
    { key: 25, label: "CD 22", viewing: false },
    { key: 26, label: "CD 23", viewing: false },
    { key: 27, label: "CD 24", viewing: false },
    { key: 28, label: "CD 25", viewing: false },
    { key: 29, label: "CD 26", viewing: false },
    { key: 30, label: "CD 27", viewing: false },
    { key: 31, label: "CD 28", viewing: false },
    { key: 32, label: "CD 29", viewing: false },
    { key: 33, label: "CD 30", viewing: false },
    { key: 34, label: "CD 31", viewing: false },
    { key: 35, label: "CD 32", viewing: false },
    { key: 36, label: "CD 33", viewing: false },
    { key: 37, label: "CD 34", viewing: false },
    { key: 38, label: "CD 35", viewing: false },
    { key: 39, label: "CD 36", viewing: false },
    { key: 40, label: "CD 37", viewing: false },
    { key: 41, label: "CD 38", viewing: false },
    { key: 42, label: "CD 39", viewing: false },
    { key: 43, label: "CD 40", viewing: false },
    { key: 44, label: "CD 41", viewing: false },
    { key: 45, label: "CD 42", viewing: false },
    { key: 46, label: "CD 43", viewing: false },
    { key: 47, label: "CD 44", viewing: false },
    { key: 48, label: "CD 45", viewing: false },
    { key: 49, label: "CD 46", viewing: false },
    { key: 50, label: "CD 47", viewing: false },
    { key: 51, label: "CD 48", viewing: false },
    { key: 52, label: "CD 49", viewing: false },
    { key: 53, label: "CD 50", viewing: false },
    { key: 54, label: "CD 50 +", viewing: false },
  ];
};

export const getDaysPastOvulation = () => {
  return [
    { key: 55, header: "Days_Past_Ovulation", id: "Days_Past_Ovulation" },
    { key: 56, label: "1 DPO", viewing: false },
    { key: 57, label: "2 DPO", viewing: false },
    { key: 58, label: "3 DPO", viewing: false },
    { key: 59, label: "4 DPO", viewing: false },
    { key: 60, label: "5 DPO", viewing: false },
    { key: 61, label: "6 DPO", viewing: false },
    { key: 62, label: "7 DPO", viewing: false },
    { key: 63, label: "8 DPO", viewing: false },
    { key: 64, label: "9 DPO", viewing: false },
    { key: 65, label: "10 DPO", viewing: false },
    { key: 66, label: "11 DPO", viewing: false },
    { key: 67, label: "12 DPO", viewing: false },
    { key: 68, label: "13 DPO", viewing: false },
    { key: 69, label: "14 DPO", viewing: false },
    { key: 70, label: "15 DPO", viewing: false },
    { key: 71, label: "16 DPO", viewing: false },
    { key: 72, label: "17 DPO", viewing: false },
    { key: 73, label: "18 DPO", viewing: false },
    { key: 74, label: "19 DPO", viewing: false },
    { key: 75, label: "20+ DPO", viewing: false },
  ];
};

export const getDaysPastTransfer = () => {
  return [
    { key: 76, header: "Days_Past_Transfer", id: "Days_Past_Transfer" },
    { key: 77, label: "1 DPT", viewing: false },
    { key: 78, label: "2 DPT", viewing: false },
    { key: 79, label: "3 DPT", viewing: false },
    { key: 80, label: "4 DPT", viewing: false },
    { key: 81, label: "5 DPT", viewing: false },
    { key: 82, label: "6 DPT", viewing: false },
    { key: 83, label: "7 DPT", viewing: false },
    { key: 84, label: "8 DPT", viewing: false },
    { key: 85, label: "9 DPT", viewing: false },
    { key: 86, label: "10 DPT", viewing: false },
    { key: 87, label: "11 DPT", viewing: false },
    { key: 88, label: "12 DPT", viewing: false },
    { key: 89, label: "13 DPT", viewing: false },
    { key: 90, label: "14 DPT", viewing: false },
    { key: 91, label: "15 DPT", viewing: false },
    { key: 92, label: "16 DPT", viewing: false },
    { key: 93, label: "17 DPT", viewing: false },
    { key: 94, label: "18 DPT", viewing: false },
    { key: 95, label: "19 DPT", viewing: false },
    { key: 96, label: "20+ DPT", viewing: false },
  ];
};
